import React, { useState } from 'react';
import { css } from '@emotion/css';
import { colors } from "./colors";
import { dimensions } from "./dimensions";

const TriangleTool = () => {
    const [corners, setCorners] = useState({
        0: { x: 200, y: 100 },
        1: { x: 100, y: 300 },
        2: { x: 300, y: 300 },
    });
    const [lines, setLines] = useState({
        0: {x1: 200, y1: 100, x2: 100, y2: 300},
        1: {x1: 100, y1: 300, x2: 300, y2: 300},
        2: {x1: 200, y1: 100, x2: 300, y2: 300}
    });
    const [isDragging, setIsDragging] = useState({
        0: false,
        1: false,
        2: false
    });

    const handleMouseDown = (event: any, index: number) => {
        setIsDragging({...isDragging, [index]: true});
    };

    const handleMouseUp = () => {
        if (isDragging[0]){
            setIsDragging({...isDragging, 0: false})
        }

        if (isDragging[1]){
            setIsDragging({...isDragging, 1: false})
        }

        if (isDragging[2]){
            setIsDragging({...isDragging, 2: false})
        }
    };

    const handleMouseMove = (event: any) => {
        const area = event.currentTarget.getBoundingClientRect();
        const xCordinate: number = event.clientX - area.left;
        const yCordinate: number = event.clientY - area.top;

        const newPosition = {
            x: xCordinate,
            y: yCordinate
        }

        if (isDragging[0]){
            setCorners({...corners, 0: newPosition});

            setLines({...lines,
                0: {
                ...lines[0], x1: xCordinate, y1: yCordinate
                },
                2: {
                ...lines[2], x1: xCordinate, y1: yCordinate
                }
            });
        }

        if (isDragging[1]){
            setCorners({...corners, 1: newPosition});

            setLines({...lines,
                0: {
                    ...lines[0], x2: xCordinate, y2: yCordinate
                },
                1: {
                    ...lines[1], x1: xCordinate, y1: yCordinate
                }
            });
        }

        if (isDragging[2]){
            setCorners({...corners, 2: newPosition});

            setLines({...lines,
                1: {
                    ...lines[1], x2: xCordinate, y2: yCordinate
                },
                2: {
                    ...lines[2], x2: xCordinate, y2: yCordinate
                }
            });
        }
    };

    // start style
    const boxStyle = css({
        border: `2px solid ${colors.primaryDark}`,
        position: 'relative',
        borderRadius: '10px',
        width: `${dimensions.boxWidth}px`,
        height: `${dimensions.boxHeight}px`
    });

    const circleStyle = css({
        fill: `${colors.primaryBlue}`,
    });

    const lineStyle = css({
        stroke: `${colors.lightBlue}`,
    });
    // end style

    return (
        <svg
            className={boxStyle}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            {Object.entries(lines).map(([index, line]) => (
                <line
                    key={index}
                    x1={line.x1}
                    y1={line.y1}
                    x2={line.x2}
                    y2={line.y2}
                    className={lineStyle}
                />
            ))}

            {Object.entries(corners).map(([index, corner]) => (
                <circle
                    key={index}
                    cx={corner.x}
                    cy={corner.y}
                    r={dimensions.circleRadius}
                    className={circleStyle}
                    onMouseDown={(e) => handleMouseDown(e, Number(index))}
                />
            ))}
        </svg>
    );
};

export default TriangleTool;
